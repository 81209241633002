import { All, zeros } from '@odiak/numjs'
import {
  bwr,
  wg,
  wo,
  wp,
} from '../../../calculator/heatmap/color/scalarToColor'
import { SelectionState, UserMapSubject } from './state'
import { Results } from '../../../calculator/resources/types/result'
import { Labels } from '../../../calculator/resources/types/labels'
import { unique } from '../../../utils/unique'

export type MapData = {
  data: number[]
  coloring: (v: number) => string
  description: string
}

export function getUserMapData(
  selection: SelectionState,
  results: Results,
  labels: Labels,
): MapData | undefined {
  switch (selection.userMapSubject) {
    case 'distribution': {
      // カスタマー属性の分布
      if (!selection.showUsersAsDots && selection.userAttribute.length > 0) {
        const K = results.model1.config.latent_resolution
        const N = labels.user_label.length
        let d = zeros(K * K)
        for (let i = 0; i < N; i++) {
          const match = selection.userAttribute.some(
            (j) => labels.user_attr_nd.get([i, j]) > 0,
          )
          if (!match) continue
          const k = results.model1.k_star1[i]!
          d.data[k] += 1
        }
        d = d.div(N / (K * K) / 0.8)
        return {
          data: d.data,
          coloring: wg,
          description: '選択されたカスタマー属性の分布',
        }
      }
      break
    }

    case 'preference-or-impression': {
      // 選択された評価項目での評価値
      if (selection.preference !== undefined) {
        if (selection.item !== undefined) {
          const { data } = results.model1.y.slice(
            All,
            selection.item,
            selection.preference,
          )
          return {
            data,
            coloring: bwr,
            description: '選択された焼物と好みの評価',
          }
        }

        const { data } = results.model1.y
          .slice(All, All, selection.preference)
          .mean([1])
        return {
          data,
          coloring: bwr,
          description: '全ての焼物への平均の評価（選択された評価項目について）',
        }
      }

      // 選択された印象に対する評価値
      if (selection.impression !== undefined) {
        if (selection.item !== undefined) {
          const { data } = results.model2.y
            .slice(All, selection.item, selection.impression)
            .mean([])
          return {
            data,
            coloring: wo,
            description: '選択された印象と焼物に対する評価',
          }
        }

        const { data } = results.model2.y
          .slice(All, All, selection.impression)
          .mean([1, 2])
        return {
          data,
          coloring: wo,
          description: '選択された印象に対する全ての焼物への平均の評価',
        }
      }

      break
    }

    case 'question': {
      if (selection.question !== undefined) {
        const { data } = results.model3.y.slice(All, selection.question)
        return {
          data,
          coloring: wp,
          description: '選択された生活意識に対する評価',
        }
      }

      break
    }
  }
}

export function getPossibleUserMapSubjects(
  selection: SelectionState,
): UserMapSubject[] {
  const subjects: UserMapSubject[] = []

  if (!selection.showUsersAsDots && selection.userAttribute.length > 0) {
    subjects.push('distribution')
  }
  if (
    selection.preference !== undefined ||
    selection.impression !== undefined ||
    selection.item !== undefined
  ) {
    subjects.push('preference-or-impression')
  }
  if (selection.question !== undefined) {
    subjects.push('question')
  }

  return subjects
}

export function getItemMapData(
  selection: SelectionState,
  results: Results,
): MapData | undefined {
  // 選択された評価項目での評価値
  if (selection.preference !== undefined) {
    if (selection.user !== undefined) {
      const { data } = results.model1.y.slice(
        selection.user,
        All,
        selection.preference,
      )
      return {
        data,
        coloring: bwr,
        description:
          '選択されたカスタマーの焼物に対する評価（選択された評価項目について）',
      }
    }

    const { data } = results.model1.y
      .slice(All, All, selection.preference)
      .mean([0])
    return {
      data,
      coloring: bwr,
      description:
        '全てのカスタマーの焼物に対する平均評価（選択された評価項目について）',
    }
  }

  // 選択された印象に対する評価値
  if (selection.impression !== undefined) {
    if (selection.user !== undefined) {
      const { data } = results.model2.y.slice(
        selection.user,
        All,
        selection.impression,
      )
      return {
        data,
        coloring: wo,
        description: '選択されたカスタマーの焼物に対する、選択された印象',
      }
    }

    const { data } = results.model2.y
      .slice(All, All, selection.impression)
      .mean([0])
    return {
      data,
      coloring: wo,
      description: '全てのカスタマーの焼物に対する、選択された印象の平均',
    }
  }
}

export function getImpressionMapData(
  selection: SelectionState,
  results: Results,
): MapData | undefined {
  if (selection.user !== undefined && selection.item !== undefined) {
    const { data } = results.model2.y.slice(selection.user, selection.item, All)
    return {
      data,
      coloring: wo,
      description: '選択されたカスタマーの焼物に対する印象',
    }
  }

  if (selection.user !== undefined) {
    const { data } = results.model2.y.slice(selection.user, All, All).mean([0])
    return {
      data,
      coloring: wo,
      description: '選択されたカスタマーの焼物に対する印象の平均',
    }
  }

  if (selection.item !== undefined) {
    const { data } = results.model2.y.slice(All, selection.item, All).mean([0])
    return {
      data,
      coloring: wo,
      description: '選択された焼物に対する印象の平均',
    }
  }
}

export function getQuestionMapData(
  selection: SelectionState,
  results: Results,
): MapData | undefined {
  if (selection.user !== undefined) {
    const { data } = results.model3.y.slice(selection.user, All, All)
    return {
      data,
      coloring: wp,
      description: '選択されたカスタマーの生活意識',
    }
  }
}

export function getNumberOfUsersForSegments(
  selection: SelectionState,
  results: Results,
  labels: Labels,
): number[] {
  const { user: selectedUserUnit } = selection
  const { k_star1: kStar1 } = results.model1

  return labels.user_attr_label.map(
    (_, i) =>
      labels.user_label.filter(
        (_, j) =>
          (selectedUserUnit === undefined || kStar1[j] === selectedUserUnit) &&
          labels.user_attr_nd.get([j, i]) > 0,
      ).length,
  )
}

export function getItemLabels(results: Results, labels: Labels) {
  const labelsForMap: string[][] = new Array(144).fill(0).map(() => [])
  const { k_star2: i2k } = results.model2
  for (const [i, { brand_name }] of labels.item_label.entries()) {
    labelsForMap[i2k[i]!]?.push(brand_name)
  }
  return labelsForMap.map((e) => (e.length === 0 ? undefined : unique(e)))
}

export function getImpressionLabels(results: Results, labels: Labels) {
  const labelsForMap: string[][] = new Array(144).fill(0).map(() => [])
  const { k_star3: i2k } = results.model2
  for (const [i, { label }] of labels.impression_label.entries()) {
    labelsForMap[i2k[i]!]?.push(label)
  }
  return labelsForMap.map((e) => (e.length === 0 ? undefined : e))
}

export function getQuestionLabels(results: Results, labels: Labels) {
  const { k_star2: kStar2 } = results.model3
  const labelsForMap: (string[] | undefined)[] = new Array(144)
    .fill(0)
    .map(() => undefined)

  for (const [i] of labels.lifestyle_label.entries()) {
    labelsForMap[kStar2[i]!] = []
  }
  return labelsForMap
}

const kindMap: Record<string, string> = {
  coffee: 'コーヒーカップ',
  tumbler: 'タンブラー',
  meshi: '飯碗',
  sara: '皿',
}

export function getItemsOnSelectedUnit(
  selection: Pick<SelectionState, 'item'>,
  results: Results,
  labels: Labels,
) {
  const selectedUnit = selection.item
  if (selectedUnit === undefined) return undefined

  const { k_star2: kStar2 } = results.model2

  return labels.item_label
    .filter((_, i) => selectedUnit === undefined || kStar2[i] === selectedUnit)
    .map(({ item_type, img_path, brand_name }) => ({
      kind: kindMap[item_type] ?? item_type,
      imageUrl: `/data/images/${img_path}`,
      brand: brand_name,
    }))
}

export function getQuestionsOnSelectedUnit(
  selection: SelectionState,
  results: Results,
  labels: Labels,
) {
  const { question: selectedUnit } = selection
  if (selectedUnit === undefined) return undefined

  const { k_star2: kStar2 } = results.model3

  return labels.lifestyle_label
    .filter((_, i) => selectedUnit === undefined || kStar2[i] === selectedUnit)
    .map(({ section_name, question }) => ({
      category: section_name,
      question,
    }))
}

export function getNumberOfUsersForUnits(
  selection: SelectionState,
  results: Results,
  labels: Labels,
) {
  const K = results.model1.config.latent_resolution
  const N = labels.user_label.length
  const data = new Array<number>(K * K).fill(0)
  if (selection.showUsersAsDots) {
    for (let i = 0; i < N; i++) {
      if (selection.userAttribute.length > 0) {
        const match = selection.userAttribute.some(
          (j) => labels.user_attr_nd.get([i, j]) > 0,
        )
        if (!match) continue
      }
      const k = results.model1.k_star1[i]!
      data[k] += 1
    }
  }
  return data
}

export function getColorForPreferences(
  selection: SelectionState,
  results: Results,
): string[] | undefined {
  if (selection.item !== undefined && selection.user !== undefined) {
    const { data } = results.model1.y.slice(selection.user, selection.item, All)
    return data.map(bwr)
  }

  if (selection.item !== undefined) {
    const { data } = results.model1.y.slice(All, selection.item, All).mean([0])
    return data.map(bwr)
  }

  if (selection.user !== undefined) {
    const { data } = results.model1.y.slice(selection.user, All, All).mean([0])
    return data.map(bwr)
  }
}
