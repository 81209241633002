import React, {
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Results } from '../../../calculator/resources/types/result'
import { Labels } from '../../../calculator/resources/types/labels'
import { styled } from 'styled-components'
import { SOMMap } from '../../SOMMap'
import { usePersistedState } from '../../../utils/usePersistedState'
import {
  getUserMapData,
  getItemMapData,
  getImpressionMapData,
  getQuestionMapData,
  getNumberOfUsersForSegments,
  getItemLabels,
  getImpressionLabels,
  getQuestionLabels,
  getItemsOnSelectedUnit,
  getQuestionsOnSelectedUnit,
  getNumberOfUsersForUnits,
  MapData,
  getPossibleUserMapSubjects,
  getColorForPreferences,
} from './calculation'
import { SelectionState, useSelectionState } from './state'
import { wg } from '../../../calculator/heatmap/color/scalarToColor'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
`

const MapContainer = styled.div<{ size: number }>`
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 8px;
  width: ${(p) => p.size + 50}px;
  background: #fff;
`

const MapTitle = styled.h2`
  margin: 0;
  margin-top: 8px;
  font-size: 18px;
  text-align: center;
`

const MapDescription = styled.p`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 14px;
  text-align: center;
`

const ResetButtonContainer = styled.div`
  padding-right: 8px;
  padding-bottom: 4px;
  text-align: right;
`

const padding = 25

const mapSizes = [
  216, 228, 240, 252, 264, 276, 288, 300, 312, 324, 336, 348, 360,
]

export const V2: FC<{ results: Results; labels: Labels }> = ({
  results,
  labels,
}) => {
  const [selection, dispatch] = useSelectionState()

  const [applyUserScale, renderUserScaleController] = useScale()
  const [applyItemScale, renderItemScaleController] = useScale()
  const [applyImpressionScale, renderImpressionScaleController] = useScale()
  const [applyQuestionScale, renderQuestionScaleController] = useScale()

  const userMapData = useMemo(
    () => applyUserScale(getUserMapData(selection, results, labels)),
    [applyUserScale, selection, results, labels],
  )
  const itemMapData = useMemo(
    () => applyItemScale(getItemMapData(selection, results)),
    [applyItemScale, results, selection],
  )
  const impressionMapData = useMemo(
    () => applyImpressionScale(getImpressionMapData(selection, results)),
    [applyImpressionScale, results, selection],
  )
  const questionMapData = useMemo(
    () => applyQuestionScale(getQuestionMapData(selection, results)),
    [applyQuestionScale, results, selection],
  )

  const onChangeUserUnit = (user: number | undefined) => {
    dispatch({ type: 'update', value: { user } })
  }
  const onChangeItemUnit = (item: number | undefined) => {
    dispatch({ type: 'update', value: { item } })
  }
  const onChangeImpressionUnit = (impression: number | undefined) => {
    dispatch({ type: 'update', value: { impression } })
    if (impression !== undefined) {
      dispatch({ type: 'update', value: { preference: undefined } })
    }
  }
  const onChangeQuestionUnit = (question: number | undefined) => {
    dispatch({ type: 'update', value: { question } })
  }
  const onChangePreference = (preference: number | undefined) => {
    dispatch({ type: 'update', value: { preference } })
    if (preference !== undefined) {
      dispatch({ type: 'update', value: { impression: undefined } })
    }
  }

  const impressionLabels = useMemo(
    () => getImpressionLabels(results, labels),
    [results, labels],
  )

  const itemLabels = useMemo(
    () => getItemLabels(results, labels),
    [results, labels],
  )

  const questionLabels = useMemo(
    () => getQuestionLabels(results, labels),
    [results, labels],
  )

  const numberOfUsersForSegments = useMemo(
    () => getNumberOfUsersForSegments(selection, results, labels),
    [results, labels, selection],
  )
  const numberOfUsersForUnits = useMemo(
    () => getNumberOfUsersForUnits(selection, results, labels),
    [labels, results, selection],
  )

  const itemsOnSelectedUnit = useMemo(
    () => getItemsOnSelectedUnit({ item: selection.item }, results, labels),
    [results, labels, selection.item],
  )

  const questionsOnSelectedUnit = useMemo(
    () => getQuestionsOnSelectedUnit(selection, results, labels),
    [results, labels, selection],
  )

  const [mapSize, setMapSize] = usePersistedState('TensorCube/mapSize', 300)

  const preferenceColors = useMemo(
    () => getColorForPreferences(selection, results),
    [selection, results],
  )

  return (
    <>
      <ResetButtonContainer>
        <button onClick={() => dispatch({ type: 'reset' })}>リセット</button>
      </ResetButtonContainer>

      <Container>
        <MapContainer size={mapSize}>
          <MapTitle>カスタマーマップ</MapTitle>
          <SOMMap
            {...userMapData}
            size={mapSize}
            padding={padding}
            selectedUnit={selection.user}
            resolution={12}
            onChangeSelectedUnit={onChangeUserUnit}
            showDots={numberOfUsersForUnits}
          />
          <MapDescription>
            {userMapData
              ? `カラー: ${userMapData.description}`
              : '(色付けなし)'}
          </MapDescription>
          {renderUserScaleController()}
          <UserMapSubjectSelector
            state={selection}
            onChange={(v) =>
              dispatch({ type: 'update', value: { userMapSubject: v } })
            }
          />
          <UserSegmentSelector
            userAttribute={selection.userAttribute}
            onChange={(v) =>
              dispatch({ type: 'update', value: { userAttribute: v } })
            }
            numbers={numberOfUsersForSegments}
            showColors={selection.user !== undefined}
          />
          <div style={{ marginTop: 8 }}>
            <label>
              <input
                type="checkbox"
                checked={selection.showUsersAsDots}
                onChange={(e) =>
                  dispatch({
                    type: 'update',
                    value: { showUsersAsDots: e.target.checked },
                  })
                }
              />
              カスタマーを点で表示する
            </label>
          </div>
        </MapContainer>
        <MapContainer size={mapSize}>
          <MapTitle>印象マップ</MapTitle>
          <SOMMap
            {...impressionMapData}
            size={mapSize}
            padding={padding}
            selectedUnit={selection.impression}
            resolution={12}
            onChangeSelectedUnit={onChangeImpressionUnit}
            labels={impressionLabels}
          />
          <MapDescription>
            {impressionMapData
              ? `カラー: ${impressionMapData.description}`
              : '(色付けなし)'}
          </MapDescription>
          {renderImpressionScaleController()}
          <PreferenceSelector
            preference={selection.preference}
            onChange={onChangePreference}
            colors={preferenceColors}
          />
        </MapContainer>
        <MapContainer size={mapSize}>
          <MapTitle>焼物マップ</MapTitle>
          <SOMMap
            {...itemMapData}
            size={mapSize}
            padding={padding}
            selectedUnit={selection.item}
            resolution={12}
            onChangeSelectedUnit={onChangeItemUnit}
            labels={itemLabels}
          />
          <MapDescription>
            {itemMapData
              ? `カラー: ${itemMapData.description}`
              : '(色付けなし)'}
          </MapDescription>
          {renderItemScaleController()}
          {itemsOnSelectedUnit && <ItemList items={itemsOnSelectedUnit} />}
        </MapContainer>
        <MapContainer size={mapSize}>
          <MapTitle>生活意識マップ</MapTitle>
          <SOMMap
            {...questionMapData}
            size={mapSize}
            padding={padding}
            selectedUnit={selection.question}
            resolution={12}
            onChangeSelectedUnit={onChangeQuestionUnit}
            labels={questionLabels}
          />
          <MapDescription>
            {questionMapData
              ? `カラー: ${questionMapData.description}`
              : '(色付けなし)'}
          </MapDescription>
          {renderQuestionScaleController()}
          {questionsOnSelectedUnit && (
            <QuestionList questions={questionsOnSelectedUnit} />
          )}
        </MapContainer>
      </Container>
      <MapSizeSelector
        candidates={mapSizes}
        size={mapSize}
        onChange={setMapSize}
      />
    </>
  )
}

const PreferenceSelectorForm = styled.form`
  display: block;

  label {
    display: block;
  }
`

const preferenceLabels = [
  '好き',
  '日常的に使いたい',
  '特別な日に使いたい',
  '贈り物に使いたい',
]

const PreferenceSelector: FC<{
  preference: number | undefined
  onChange: (v: number | undefined) => void
  colors: string[] | undefined
}> = ({ preference, onChange, colors }) => {
  return (
    <PreferenceSelectorForm>
      <label>
        <input
          type="radio"
          name="preference"
          value="undefined"
          checked={preference === undefined}
          onChange={() => onChange(undefined)}
        />
        選択なし（上の印象マップから選択する）
      </label>
      {preferenceLabels.map((label, i) => (
        <label key={i} style={{ background: colors?.[i] }}>
          <input
            type="radio"
            name="preference"
            value={i}
            checked={preference === i}
            onChange={() => onChange(i)}
          />
          {label}
        </label>
      ))}
    </PreferenceSelectorForm>
  )
}

const UserSegmentSelectorForm = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;

  > label {
    background-color: #fff;
    transition: background-color 0.3s ease;
  }
`

function toggle(selected: number[], value: number): number[] {
  const index = selected.indexOf(value)
  if (index === -1) {
    return [...selected, value]
  } else {
    const a = [...selected]
    a.splice(index, 1)
    return a
  }
}

const UserSegmentSelector: FC<{
  userAttribute: number[]
  onChange: (v: number[]) => void
  numbers: number[]
  showColors?: boolean
}> = ({ userAttribute, onChange, numbers, showColors }) => {
  const total = useMemo(() => numbers.reduce((a, b) => a + b, 0), [numbers])

  return (
    <>
      <div>カスタマー属性:</div>
      <UserSegmentSelectorForm>
        {['20代', '30代', '40代', '50代', '60代', '>70代'].map((a, i) => (
          <Fragment key={i}>
            {['男', '女'].map((g, j) => {
              const attributeIndex = i + j * 6
              const n = numbers[attributeIndex]!
              return (
                <label
                  key={j}
                  style={{ background: showColors ? wg(n / total) : 'unset' }}
                >
                  <input
                    type="checkbox"
                    value={attributeIndex}
                    checked={userAttribute.includes(attributeIndex)}
                    onChange={() => {
                      onChange(toggle(userAttribute, attributeIndex))
                    }}
                  />
                  {a} {g}{' '}
                  <span style={{ fontSize: '12px' }}>
                    {n > 0 && `(${n}人)`}
                  </span>
                </label>
              )
            })}
          </Fragment>
        ))}
      </UserSegmentSelectorForm>
      <button onClick={() => onChange([])}>全ての選択を解除</button>
    </>
  )
}

const ItemListContainer = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  font-size: 12px;

  img {
    width: 100%;
    height: auto;
  }
`

const ItemList: FC<{
  items: { kind: string; imageUrl: string; brand: string }[]
}> = ({ items }) => {
  const [show, setShow] = useState(false)

  // アイテムが変わったら、一瞬だけ非表示にする
  // （マップ上をドラッグするときに表示が頻繁に変わるのを防ぐため）
  useEffect(() => {
    setShow(false)
    const timer = setTimeout(() => {
      setShow(true)
    }, 100)
    return () => {
      clearTimeout(timer)
    }
  }, [items])

  return (
    <ItemListContainer style={{ visibility: show ? 'visible' : 'hidden' }}>
      {items.map(({ kind, imageUrl, brand }) => (
        <div key={imageUrl}>
          <img src={imageUrl} width="750" height="500" />
          <div>
            {brand} / {kind}
          </div>
        </div>
      ))}
    </ItemListContainer>
  )
}

const QuestionListContainer = styled.ul``

const QuestionList: FC<{
  questions: { category: string; question: string }[]
}> = ({ questions }) => {
  return (
    <QuestionListContainer>
      {questions.map(({ category, question }) => (
        <li key={question}>
          <span style={{ color: '#999' }}>{category} / </span>
          {question}
        </li>
      ))}
    </QuestionListContainer>
  )
}

const MapSizeSelectorContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
`

const MapSizeSelector: FC<{
  candidates: number[]
  size: number
  onChange: (v: number) => void
}> = ({ candidates, size, onChange }) => {
  const [index, setIndex] = useState(() => candidates.indexOf(size))

  return (
    <MapSizeSelectorContainer>
      <input
        type="range"
        min={0}
        max={candidates.length - 1}
        value={index}
        onChange={(e) => {
          const i = e.target.valueAsNumber
          setIndex(i)
          onChange(candidates[i]!)
        }}
      />
    </MapSizeSelectorContainer>
  )
}

type ScaleParams = {
  min: number
  max: number
}

const defaultScaleParams: ScaleParams = { min: 0, max: 1 }

function applyScale(
  mapData: MapData | undefined,
  params: ScaleParams,
): MapData | undefined {
  if (mapData === undefined) return mapData
  if (params.min === 0 && params.max === 1) return mapData

  return {
    ...mapData,
    data: mapData.data.map((v) => (v - params.min) / (params.max - params.min)),
  }
}

function useScale(): [
  apply: (mapData: MapData | undefined) => MapData | undefined,
  render: () => ReactNode,
] {
  const [params, setParams] = useState(defaultScaleParams)

  const apply = useCallback(
    (mapData: MapData | undefined) => {
      return applyScale(mapData, params)
    },
    [params],
  )

  const renderController = useCallback((): ReactNode => {
    return <ScaleController scaleParams={params} onChange={setParams} />
  }, [params])

  return [apply, renderController]
}

const ScaleControlContainer = styled.div`
  opacity: 0.2;
  font-size: 12px;
  margin-bottom: 10px;

  button {
    font-size: 10px;
    margin-right: 4px;
  }

  input[type='range'] {
    width: 80px;
  }

  &:hover {
    opacity: 1;
  }
`

const ScaleController: FC<{
  scaleParams: ScaleParams
  onChange: (p: ScaleParams) => void
}> = ({ scaleParams, onChange }) => {
  const [showScaleSettings, setShowScaleSettings] = useState(false)

  return (
    <ScaleControlContainer>
      <div>
        {showScaleSettings ? (
          <button onClick={() => setShowScaleSettings(false)}>
            スケール設定を閉じる
          </button>
        ) : (
          <button onClick={() => setShowScaleSettings(true)}>
            スケール設定を表示する
          </button>
        )}
        {(scaleParams.min !== 0 || scaleParams.max !== 1) && (
          <button onClick={() => onChange({ min: 0, max: 1 })}>リセット</button>
        )}
      </div>
      {showScaleSettings && (
        <>
          最小値:
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={scaleParams.min}
            onChange={(e) =>
              onChange({ ...scaleParams, min: parseFloat(e.target.value) })
            }
          />
          / 最大値:
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={scaleParams.max}
            onChange={(e) =>
              onChange({ ...scaleParams, max: parseFloat(e.target.value) })
            }
          />
        </>
      )}
    </ScaleControlContainer>
  )
}

const UserMapSubjectSelectorContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;

  > label {
    display: inline-block;
  }
`

const userMapSubjectLabels = {
  distribution: 'カスタマー属性の分布',
  'preference-or-impression': '印象/評価',
  question: '生活意識',
} as const

const UserMapSubjectSelector: FC<{
  state: SelectionState
  onChange: (sub: SelectionState['userMapSubject']) => void
}> = ({ state, onChange }) => {
  const subjects = useMemo(() => getPossibleUserMapSubjects(state), [state])

  if (subjects.length <= 1) return null

  return (
    <UserMapSubjectSelectorContainer>
      <span style={{ marginRight: 4 }}>表示するもの:</span>
      {subjects.map((s) => (
        <label key={s}>
          <input
            type="radio"
            name="userMapSubject"
            value={s}
            checked={s === state.userMapSubject}
            onChange={() => onChange(s)}
          />
          {s && userMapSubjectLabels[s]}
        </label>
      ))}
    </UserMapSubjectSelectorContainer>
  )
}
